@import "~@/erp/styles/variables/variables.scss";
































































































::v-deep {
  .el-dialog__header {
    text-align: left;
  }
}
.line-one {
  width: 125px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.packing-detail-wrapper {
  display: flex;
  align-items: flex-end;
  .content {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 5px;
  }
  .item {
    width: 20px;
  }
}
.el-icon-edit {
  cursor: pointer;
  color: #409eff;
  cursor: 'pointer';
}
